import { ScrollCaptain } from 'scroll-captain';
import './image-text-mosaic.scss';

class ImageTextMosaic {
    constructor (element, options) {
        // Default options
        const defaults = {
            initAttr: 'data-imagetextmosaic',
            mediaEvenValue: 'media-even',
            mediaOddValue: 'media-odd',
            breakpoint: 768
        };

        // Merge settings
        this.settings = Object.assign({}, defaults, options);

        // DOM Elements
        this.$root = element;

        // Options
        this.animation = null;
        this.breakpoint = window.matchMedia(`(min-width: ${this.settings.breakpoint}px)`);
        this.breakpointActive = this.breakpoint.matches;
    }

    initialize () {
        this.loadAnimation();
        this.setEvents();
    }

    loadAnimation () {        
        this.animation = new ScrollCaptain(this.$root, {
            initAttr: this.settings.initAttr,
            animateMediaEven: {
                targetSuffix: this.settings.mediaEvenValue,
                onscroll: true,
                translateY: this.breakpoint.matches && !window.reducedMotionActive ? { 0: -35, 100: 35 } : null
            },
            animateMediaOdd: {
                targetSuffix: this.settings.mediaOddValue,
                onscroll: true,
                translateY: this.breakpoint.matches && !window.reducedMotionActive ? { 0: 35, 100: -35 } : null
            },
            updateOnResize: () => {
                if (this.breakpoint.matches !== this.breakpointActive) {
                    this.breakpointActive = this.breakpoint.matches;
                    this.updateAnimation(false);
                }
            }
        });
    }

    updateAnimation (updateRequired) {    
        this.animation.defaultOptions.animateMediaEven.translateY = this.breakpoint.matches && !window.reducedMotionActive ? { 0: -35, 100: 35 } : null;
        this.animation.defaultOptions.animateMediaOdd.translateY = this.breakpoint.matches && !window.reducedMotionActive ? { 0: 35, 100: -35 } : null;

        if (updateRequired) {
            this.animation.update();
        }
    }

    setEvents () {
        window.addEventListener('motionPreferenceChanged', () => {
            this.updateAnimation(true);
        });
    }
}

export { ImageTextMosaic };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$roots = $context.querySelectorAll('[data-imagetextmosaic="root"]');
        $$roots.forEach($root => {
            const $rootAPI = new ImageTextMosaic($root);
            $rootAPI.initialize();
        });
    }
});
